<template>
<!-- <p>change email</p> -->
<CRow>
  <CCol v-if="isRender">
    <CCard bodyWrapper>
      <!-- <CLabel htmlFor="nf-email">Email</CLabel> -->
      <!-- <CRow>
        <CCol sm="5">
          <h6>Email ปัจจุบัน</h6>
        </CCol>
        <CCol sm="2">
        </CCol>
        <CCol sm="5">
          <h6>Email ที่ต้องการเป็น</h6>
        </CCol>
      </CRow> -->
      <CRow>
        <CCol sm="5">
          <h6>Email ปัจจุบัน</h6>
          <CInput
            type="email"
            placeholder="Enter Email.."
            autoComplete="email"
            :value="originEmail"
            readonly
            size="lg"
          />
        </CCol>
        <CCol sm="2" class="text-center">
          <i class="fas fa-exchange-alt fa-3x"></i>
        </CCol>
        <CCol sm="5">
          <h6>Email ที่ต้องการเป็น</h6>
          <CInput
            type="email"
            id="nf-email"
            name="nf-email"
            placeholder="Enter Email.."
            autoComplete="email"
            @update:value="emailUpdate"
            size="lg"
          />
        </CCol>
      </CRow>
      <CRow class="pt-3">
        <CCol class="text-center">
          <CButton size="lg" @click="submitChangeEmail" color="pastelpurple2">เปลี่ยน</CButton>
        </CCol>
      </CRow>
    </CCard>
  </CCol>
</CRow>
</template>

<script>
import Api from "@/services/Api"
import Swal from 'sweetalert2'

export default {
  name: 'ChangeEmail',
  data(){
    return {
      originEmail:localStorage.getItem("email"),
      newEmail: "",
      isRender: false
    }
  },
  created() {
    console.log("Change Email")
    this.checkGrant(localStorage.getItem('email'))
  },
  methods: {
    emailUpdate (email) {
      console.log(email)
      this.newEmail = email
    },
    submitChangeEmail () {
      localStorage.setItem("email",this.newEmail)
      location.reload()
    },
    async checkGrant(email){
      let grantList = await Api.checkGrant(email)
      let foundAdminGrant = false
      if(grantList){
        console.log("grantList(API):",grantList)
        for(let i=0;i<grantList.length;i++){
          if(grantList[i].grant_on === "admin"){
            foundAdminGrant = true
            break
          }
        }
        if(!foundAdminGrant){
          this.$router.push({path: `/`})
        }else{
          this.isRender = true
          Swal.fire(
            'คำเตือน!',
            'ในเวอร์ชั่นปัจจุบันการเปลี่ยน Email เป็นผู้ใช้งานอื่นจะไม่สามารถกลับเปลี่ยนมาเป็น Email ผู้ใช้งานเดิมได้ นอกจากการ Logout และ Login อีกครั้ง จึงขอแนะนำให้ใช้งานฟังก์ชั่นนี้ในโหมดไม่ระบุตัวตน',
            'warning'
          )
        }
      }
    },
  }
}
</script>
